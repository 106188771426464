var updateButtonLink = function(autoLoginParams) {
  console.log('updateButtonLink');
  if (autoLoginParams) {
    console.log('autologinparams ', autoLoginParams);
    $('a').filter(function() {
      return matchRegex($(this).attr('href'));
    }).each(function() {
      replaceHrefValue($(this), autoLoginParams);
    });
  }
};

var matchRegex = function(value) {
  let regex = /tunnel-paiement\/(BVA|BVM)\/([a-z_]+)(\/\d{0,2})?$/;
  return regex.test(value);
}

var replaceHrefValue = function(elt, params) {
  let linkHrefValue = elt.attr('href');

  if (new URL(linkHrefValue).search.length > 0) {
    linkHrefValue += '&';
  } else {
    linkHrefValue += '?';
  }
  elt.attr('href', linkHrefValue + params.replace(/&amp;/g, "&"));
}

var getAutoLoginParams = function() {
  let currentLoginUrl = current_user.login_url;

  if (currentLoginUrl && currentLoginUrl.length > 0) {
    let parsedUrl = new URL(currentLoginUrl);
    return parsedUrl.search.substring(1);
  } else {
    return false;
  }
}

var updateOnToggle = function(autoLoginParams) {
  console.log('updateOnToggle');
  let targets = document.getElementsByClassName("bloc-bvmbva toggle-bvmbva")[0].getElementsByClassName("bg-toggle")[0].children;

  if (targets && targets.length > 0) {
    let observer = new MutationObserver(mutationList => {
      updateLinkCallback(mutationList, autoLoginParams)
    });

    if (observer) {
      for (const target of targets) {
        observer.observe(target, {attributes: true})
      }
    }
  }
}

var updateLinkCallback = function(mutationsList, autoLoginParams) {
  for (const mutation of mutationsList) {
    if (mutation.type === "attributes" && mutation.target.classList.contains("active")) {
      applyToggle(autoLoginParams);
    }
  }
}

var applyToggle = function(autoLoginParams) {
  if ($('.toggle-bvmbva').length > 0) {
    $('.bloc-bvmbva.toggle-bvmbva .offers-container a').filter(function() {
      // $(this).attr('href').split('?')[0]
      return matchRegex($(this).attr('href'));
    }).each(function() {
      replaceHrefValue($(this), autoLoginParams);
    });
  }
}

$(document).ready(function() {
  var autoLoginParams = getAutoLoginParams();
  if (autoLoginParams && autoLoginParams.length > 0) {
    updateButtonLink(autoLoginParams);
    updateOnToggle(autoLoginParams);
  }
});
