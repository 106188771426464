var collapseNavbar = function() {
  $('body').on('click', function(e) {
    let isCollapsed = $("button.navbar-toggler").hasClass("collapsed");
    if (isCollapsed === false) {

      if ( $('#navbarSupportedContent').has(e.target).length == 0 || $(e.target).is("button.navbar-toggler") || $(e.target).parent().is("button.navbar-toggler") ) {
        $('#navbarSupportedContent').collapse("hide");
      }
      toggleCpfHelpSubItem();
      toggleContactSubItem();
    }
  });

  onCpfNavbarSupportedContentCollapse();
  onContactNavbarSupportedContentCollapse();
  handleBtnLink();
}

var displayOnResizeHandler = function(clickMode) {
  if (clickMode) {
    $('#help-tooltip').addClass('tooltip-on-click');
    $('.nav-item.help-container-item').html($('#help-tooltip-content'));
    $('#help-tooltip').removeClass('tooltip-on-hover');
  }
  else {
    $('#help-tooltip').addClass('tooltip-on-hover');
    $('#help-tooltip').removeClass('tooltip-on-click');
    $('#help-tooltip-content').insertAfter($('#help-tooltip'));
    $('.nav-item.help-container-item').html();
    $('li.nav-item.help-container-item').addClass('d-none');
    $('#help-tooltip').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');

  }
}

var toggleTooltipClass = function() {
  //click mode if window size < 992, else hover mode
  displayCpfTooltipOnResizeHandler(window.innerWidth < 992);
  addMouseEventsToCpfTooltip();
  displayContactTooltipOnResizeHandler(window.innerWidth < 992);
  addMouseEventsToContactTooltip();
}

var resizeWindow = function() {
  $(window).on('resize', function() {
    toggleTooltipClass();
  });
}

var onCpfNavbarSupportedContentCollapse = function() {
  $("#navbarSupportedContent").on('hidden.bs.collapse', function (e) {
    $('#cpf-help-tooltip-content').hide();
    $('li.nav-item.cpf-help-container-item').addClass('d-none');
    $('#cpf-help-tooltip').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
  });
}

var onContactNavbarSupportedContentCollapse = function() {
  $("#navbarSupportedContent").on('hidden.bs.collapse', function (e) {
    $('#contact-tooltip-content').hide();
    $('li.nav-item.contact-container-item').addClass('d-none');
    $('#contact-tooltip').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
  });
}

var addMouseEventsToCpfTooltip = function() {
  $('#cpf-help-tooltip').on('mouseenter', function() {
    if (!$(this).hasClass('cpf-tooltip-on-hover')) return false;
    $('#cpf-help-tooltip-content').show();
    $('#cpf-help-tooltip.cpf-tooltip-on-hover').find('span').removeClass('icon-chevron-down').addClass('icon-chevron-up');
  }).on('mouseleave', function(e) {
    if (!$(this).hasClass('cpf-tooltip-on-hover')) return false;
    e.preventDefault();
    if($('#cpf-help-tooltip-content:hover').length == 0){
      $('#cpf-help-tooltip-content').hide();
      $('#cpf-help-tooltip.cpf-tooltip-on-hover').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
    } else {
      mouseHoverCpfTooltipContent();
    }
  });
}

var addMouseEventsToContactTooltip = function() {
  $('#contact-tooltip').on('mouseenter', function() {
    if (!$(this).hasClass('tooltip-on-hover')) return false;
    $('#contact-tooltip-content').show();
    $('#contact-tooltip.tooltip-on-hover').find('span').removeClass('icon-chevron-down').addClass('icon-chevron-up');
  }).on('mouseleave', function(e) {
    if (!$(this).hasClass('tooltip-on-hover')) return false;
    e.preventDefault();
    if($('#contact-tooltip-content:hover').length == 0){
      $('#contact-tooltip-content').hide();
      $('#contact-tooltip.tooltip-on-hover').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
    } else {
      mouseHoverContactTooltipContent();
    }
  });
}

var mouseHoverCpfTooltipContent = function () {
  $('#cpf-help-tooltip-content').on('mouseenter', function() {
    if ($('.cpf-tooltip-on-hover').length == 0) return false;
    $(this).show();
  }).on('mouseleave', function() {
    if ($('.cpf-tooltip-on-hover').length == 0) return false;
    $(this).hide();
    $('#cpf-help-tooltip.cpf-tooltip-on-hover').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
  });
}

var mouseHoverContactTooltipContent = function () {
  $('#contact-tooltip-content').on('mouseenter', function() {
    if ($('.tooltip-on-hover').length == 0) return false;
    $(this).show();
  }).on('mouseleave', function() {
    if ($('.tooltip-on-hover').length == 0) return false;
    $(this).hide();
    $('#contact-tooltip.tooltip-on-hover').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
  });
}

var toggleCpfHelpSubItem = function() {
  $('#cpf-help-tooltip.cpf-tooltip-on-click').off('click');
  $('#cpf-help-tooltip.cpf-tooltip-on-click').on('click', function(e) {

    if ($('.cpf-tooltip-on-click').length == 0) return false;
    $('#cpf-help-tooltip-content').show();
    $('li.nav-item.cpf-help-container-item').toggleClass('d-none');
    $('#cpf-help-tooltip.cpf-tooltip-on-click').find('span').toggleClass('icon-chevron-down icon-chevron-up');
  });
}

var toggleContactSubItem = function() {
  $('#contact-tooltip.contact-tooltip-on-click').off('click');
  $('#contact-tooltip.contact-tooltip-on-click').on('click', function(e) {

    if ($('.contact-tooltip-on-click').length == 0) return false;
    $('#contact-tooltip-content').show();
    $('li.nav-item.contact-container-item').toggleClass('d-none');
    $('#contact-tooltip.contact-tooltip-on-click').find('span').toggleClass('icon-chevron-down icon-chevron-up');
  });
}

var handleBtnLink = function() {
  if ($('#cpf-help-tooltip-permisb') != null) {
    $('#cpf-help-tooltip-permisb').on('click', function(){
      window.location.replace($('#cpf-help-tooltip-permisb-url').data('url'))
    });
  }

  if ($('#cpf-help-tooltip-info') != null) {
    $('#cpf-help-tooltip-info').on('click', function(){
      window.location.replace($('#cpf-help-tooltip-info-url').data('url'))
    });
  }
  if ($('#cpf-help-tooltip-for-children') != null) {
    $('#cpf-help-tooltip-for-children').on('click', () => window.location.replace($('#cpf-help-tooltip-for-children-url').data('url')));
  }
  if ($('#cpf-help-tooltip-offers') != null) {
    $('#cpf-help-tooltip-offers').on('click', () => {
      window.open(
        unobfuscateLink('#cpf-help-tooltip-offers-url')
      )
    });
  }
}

var displayCpfTooltipOnResizeHandler = function(clickMode) {
  if (clickMode) {
    $('#cpf-help-tooltip').addClass('cpf-tooltip-on-click');
    $('#cpf-help-tooltip').removeClass('cpf-tooltip-on-hover');
    $('.nav-item.cpf-help-container-item').html($('#cpf-help-tooltip-content'));
  }
  else {
    $('#cpf-help-tooltip').addClass('cpf-tooltip-on-hover');
    $('#cpf-help-tooltip').removeClass('cpf-tooltip-on-click');
    $('#cpf-help-tooltip-content').insertAfter($('#cpf-help-tooltip'));
    $('.nav-item.cpf-help-container-item').html();
    $('li.nav-item.cpf-help-container-item').addClass('d-none');
    $('#cpf-help-tooltip').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
    computeCpfTooltipPosition();
  }
}

var displayContactTooltipOnResizeHandler = function(clickMode) {
  if (clickMode) {
    $('#contact-tooltip').addClass('contact-tooltip-on-click');
    $('#contact-tooltip').removeClass('tooltip-on-hover');
    $('.nav-item.contact-container-item').html($('#contact-tooltip-content'));
  }
  else {
    $('#contact-tooltip').addClass('tooltip-on-hover');
    $('#contact-tooltip').removeClass('contact-tooltip-on-click');
    $('#contact-tooltip-content').insertAfter($('#contact-tooltip'));
    $('.nav-item.contact-container-item').html();
    $('li.nav-item.contact-container-item').addClass('d-none');
    $('#contact-tooltip').find('span').removeClass('icon-chevron-up').addClass('icon-chevron-down');
    computeContactTooltipPosition();
  }
}

var computeCpfTooltipPosition = function() {
  if ($('#cpf-help-tooltip').length > 0) {
    let cpfNavBarHeight = $('.navbar').outerHeight() || 0;
    let cpfParentHeight = $('.li-tooltip-parent').outerHeight() || 0;
    $('#cpf-help-tooltip-content').css({
      "right": "0",
      "top": String(cpfNavBarHeight/2 + cpfParentHeight/2) + "px"
    });
  }
}

var computeContactTooltipPosition = function() {
  if ($('#contact-tooltip').length > 0) {
    let cpfNavBarHeight = $('.navbar').outerHeight() || 0;
    let cpfParentHeight = $('.li-tooltip-parent').outerHeight() || 0;
    $('#contact-tooltip-content').css({
      "right": "0",
      "top": String(cpfNavBarHeight/2 + cpfParentHeight/2) + "px"
    });
  }
}

var unobfuscateLink = function(id) {
  let $tag = $(id)
  return $tag.hasClass('obf-link') ? window.atob($tag.data('url')) : $tag.data('url')
}

$(document).ready(function(){
  collapseNavbar();
  toggleTooltipClass();
  resizeWindow();
});
